import React from 'react'

import { InputGroup } from 'components/Form'

import './checkbox.scss'

interface Props {
  id: string
  children: React.ReactNode
  checked: boolean
  value: string
  name: string
  errorMessage?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const CheckBox: React.FC<Props> = ({
  id,
  children,
  checked,
  name,
  value,
  errorMessage,
  onChange,
  onBlur,
}) => (
  <InputGroup>
    <div className="checkbox-container">
      <input
        className="checkbox"
        type="checkbox"
        value={value}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{children}</label>
    </div>
    {errorMessage && (
      <span className={'input-group__errorMessage'}>{errorMessage}</span>
    )}
  </InputGroup>
)
