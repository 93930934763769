import React from 'react'

import Button from './Button'

interface Props {
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const SubmitButton: React.FC<Props> = ({
  children,
  className,
  disabled,
  onClick,
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) {
      return
    } else if (onClick && !disabled) {
      onClick(event)
    }
  }

  return (
    <Button
      onClick={handleOnClick}
      type={'submit'}
      className={className}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}
