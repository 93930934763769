import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
// Styling
import './navigation.scss'
import { ContentfulMainNavigationLink } from 'utils/queries'

// Props

interface LinkProp {
  title: string
  url: string
}

interface Props {
  links: ContentfulMainNavigationLink[]
}

export const Link: React.FC<LinkProp> = ({ title, url }) => {
  const to = url.startsWith('/') ? url : `/${url}`
  return (
    <li className={'main-nav__item'}>
      <GatsbyLink
        className={'main-nav__link'}
        partiallyActive={true}
        activeClassName={'main-nav__link--active'}
        to={to}
      >
        {title}
      </GatsbyLink>
    </li>
  )
}

export const Navigation: React.FC<Props> = ({ links }) => {
  return (
    <nav className={'main-nav'}>
      <ul className={'main-nav__list'}>
        {links.map((link) => (
          <Link key={link.id} title={link.title} url={link.page.slug} />
        ))}
      </ul>
    </nav>
  )
}
