import cn from 'classnames'
import React, { FC } from 'react'

import './button.scss'

interface Props {
  type: 'button' | 'submit' | 'reset'
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void
}

const Button: FC<Props> = (props) => {
  const classes = cn('btn btn--primary', props.className)
  return (
    <button
      type={props.type}
      className={classes}
      disabled={props.disabled}
      onClick={props.onClick}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      {props.children}
    </button>
  )
}

export default Button
