import React from 'react'
import { map, isEmpty } from 'lodash/fp'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'

// Components
import NewsletterForm from 'components/NewsLetterForm'

// Styling
import './footer.scss'

// Types
import { GooglePartnerBadge } from 'components/GooglePartnerBadge'
import UCSigil from 'components/UCSigil'

interface Props {
  title: string
  children: React.ReactChild
}

interface Link {
  id: string
  title: string
  page: {
    slug: string
    title: string
  }
}

interface QueryResponse {
  contentfulMenu: {
    id: string
    links: Link[]
  }
}

const Footer: React.FC<Props> = ({ title, children }) => {
  const { contentfulMenu } = useStaticQuery<QueryResponse>(graphql`
    query FooterNavigationQuery {
      contentfulMenu(location: { eq: "Footer Navigation" }) {
        id
        links {
          id
          title
          page {
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <footer className="site-footer">
      <div className="site-footer__content">
        <div className="site-footer__inner-content">
          <h3 className="site-footer__title">{title}</h3>
          <div className="site-footer__text">{children}</div>
        </div>

        {contentfulMenu && (
          <div className="site-footer__navigation">
            <ul className={'site-footer__nav-list'}>
              {!isEmpty(contentfulMenu.links) &&
                map(
                  (link) => (
                    <li key={link.id}>
                      <GatsbyLink to={`/${link.page.slug}`}>
                        {link.title}
                      </GatsbyLink>
                    </li>
                  ),
                  contentfulMenu.links
                )}
            </ul>
          </div>
        )}
        <div className="site-footer__badges">
          <UCSigil />
          <img
            width={310}
            src="https://images.ctfassets.net/k6dn8pkrw3qa/5xf41p8gsQfneOYoMhY4Iz/6574d57a9d094f6482b9906489632bf0/SIGILL_SKOLD_VANSTER_STOR_VIT_TEXT__1_.png"
          />
          <GooglePartnerBadge />
        </div>
      </div>
      <div className="site-footer__contact">
        <NewsletterForm />
      </div>

      <div className="site-footer__mobile-content">
        {contentfulMenu && (
          <div className="site-footer__navigation">
            <ul className={'site-footer__nav-list'}>
              {!isEmpty(contentfulMenu.links) &&
                map(
                  (link) => (
                    <li key={`mobile-links--${link.id}`}>
                      <GatsbyLink to={`/${link.page.slug}`}>
                        {link.title}
                      </GatsbyLink>
                    </li>
                  ),
                  contentfulMenu.links
                )}
            </ul>
          </div>
        )}
        <div className="site-footer__badges">
          <UCSigil />
          <img
            width={310}
            src="https://images.ctfassets.net/k6dn8pkrw3qa/5xf41p8gsQfneOYoMhY4Iz/6574d57a9d094f6482b9906489632bf0/SIGILL_SKOLD_VANSTER_STOR_VIT_TEXT__1_.png"
          />
          <GooglePartnerBadge />
        </div>
      </div>
    </footer>
  )
}

export default Footer
