import React from 'react'

interface Props {
  theme: 'Light' | 'Dark'
}

export const Logotype: React.FC<Props> = ({ theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="534.888"
    height="100.796"
    viewBox="0 0 534.888 100.796"
    className={`logotype logotype--${theme}`}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_942"
          d="M25.248-1.6V.41a.729.729 0 0 0-.048.138 7.485 7.485 0 0 1-.682 2.307 15.733 15.733 0 0 1-3.06 4.276 42.708 42.708 0 0 1-8.634 6.693.31.31 0 0 1-.376 0 45.593 45.593 0 0 1-7.067-5.171 21.933 21.933 0 0 1-4.047-4.707A8.458 8.458 0 0 1 .236-2.6a7.1 7.1 0 0 1 11.447-3.736c.338.288.636.624.975.955a1.067 1.067 0 0 1 .068-.1 6.88 6.88 0 0 1 6.564-2.4 6.828 6.828 0 0 1 5.658 4.913 12.7 12.7 0 0 1 .3 1.368z"
          className="logotype-shape-1"
          data-name="Path 942"
          transform="translate(0 8)"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_941"
          d="M-372 15533.967h4039.69V-88H-372z"
          className="logotype-shape-1"
          data-name="Path 941"
          transform="translate(372 88)"
        />
      </clipPath>
    </defs>
    <g
      id="Group_1574"
      data-name="Group 1574"
      transform="translate(-80.999 -164)"
    >
      <g id="Hjärta" transform="translate(590.639 242.388)">
        <g id="Group_1305" className="logotype-shape-2" data-name="Group 1305">
          <g
            id="Group_1304"
            className="logotype-shape-3"
            data-name="Group 1304"
            transform="translate(-1043.587 -218.872)"
          >
            <path
              id="Path_940"
              d="M-5-13h53.3v49.246H-5z"
              className="logotype-shape-1"
              data-name="Path 940"
              transform="translate(1034.56 218.192)"
            />
          </g>
        </g>
      </g>
      <g id="text3398-1-4" transform="translate(80.999 164)">
        <path
          id="path3212"
          d="M51.583 94.935a4.427 4.427 0 0 0-.731-1.037 1.026 1.026 0 0 0-.731-.323 17.98 17.98 0 0 0-5.65 2.516 19.969 19.969 0 0 1-9.239 2.516q-10.016-.145-13.377-9.827t-3.107-27.17q-.042-17.317 3.952-24.484t12.529-6.937a14.934 14.934 0 0 1 8.641 3.2q3.373 2.8 5.052 3.2a1.232 1.232 0 0 0 .8-.374 5 5 0 0 0 .8-.986l4.916-7.629a3.636 3.636 0 0 0 .582-1.173 3.261 3.261 0 0 0 .083-.867q-.428-2.325-7.162-6A33.568 33.568 0 0 0 34.3 15.5q-17.244.17-25.839 13.092t-8.59 36.555q-.029 24.433 8.491 36.317T34.3 113.3q9.929-.327 15.919-3.486t6.148-5.22a2.785 2.785 0 0 0-.183-.969 11.229 11.229 0 0 0-.482-1.071z"
          className="logotype-path-fill"
          transform="translate(.129 -12.504)"
        />
        <path
          id="path3214"
          d="M81.136 95.655a2.775 2.775 0 0 0 .847-.51.982.982 0 0 0 .349-.714 19.082 19.082 0 0 0-1.732-4.913q-1.512-3.26-1.728-12.769V50.5q.129-10.652-5.2-16.254T56.011 28.6a64.762 64.762 0 0 0-15.537 2.023q-7.357 1.917-7.86 4.37a3.689 3.689 0 0 0 .4 1.632l2.659 8.3a2.282 2.282 0 0 0 .465.884 1.185 1.185 0 0 0 .862.471 42.923 42.923 0 0 0 6.1-2.04 32.778 32.778 0 0 1 10.386-2.04q6.086.055 7.86 2.737a12.193 12.193 0 0 1 1.579 7.328v4.217a82.972 82.972 0 0 0-13.663.851 29.279 29.279 0 0 0-14.372 5.96q-6.311 4.9-6.662 16.176.1 9.7 5.384 15.268t14.556 5.679q7.374-.255 11.383-3.06a30.074 30.074 0 0 0 5.9-5.1 26.1 26.1 0 0 0 1.728 5.356q1.163 2.614 2.127 2.805a2.491 2.491 0 0 0 .864-.221 7.833 7.833 0 0 1 .864-.323zM62.924 82.6a21.77 21.77 0 0 1-3.739 3.2 11.4 11.4 0 0 1-6.231 1.972 8.516 8.516 0 0 1-6.281-2.635 9.5 9.5 0 0 1-2.493-6.614q.255-5.854 4.121-8.141a18.456 18.456 0 0 1 8.286-2.582 53.766 53.766 0 0 1 6.337-.161z"
          className="logotype-path-fill"
          transform="translate(28.018 .377)"
        />
        <path
          id="path3216"
          d="M145 50.089q.208-9.109-3.024-15.183T129.58 28.6q-8.068.255-12.895 3.367a46 46 0 0 0-7.71 6.019 12.286 12.286 0 0 0-5.234-6.835 15.422 15.422 0 0 0-8.325-2.55q-7.789.255-12.612 3.367a46.9 46.9 0 0 0-7.727 6.019 45.385 45.385 0 0 0-2.027-7.09q-.964-2.308-2.227-2.3a6.434 6.434 0 0 0-1.994.408l-10.1 3.4q-1.429.442-1.462 1.5a6.676 6.676 0 0 0 .655 2.63 22.856 22.856 0 0 1 1.216 5.259 103.668 103.668 0 0 1 .655 13.33V96.2a2.226 2.226 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391H74.81a2.01 2.01 0 0 0 1.529-.391 2.226 2.226 0 0 0 .332-1.513V48.456a24.978 24.978 0 0 1 4.4-3.4 14.4 14.4 0 0 1 7.299-2.176 4.8 4.8 0 0 1 4.686 3.775 24.779 24.779 0 0 1 .9 7.651V96.2a2.225 2.225 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391h13.161a2.01 2.01 0 0 0 1.529-.391 2.226 2.226 0 0 0 .332-1.513V48.456a24.976 24.976 0 0 1 4.4-3.4 14.4 14.4 0 0 1 7.295-2.176 4.8 4.8 0 0 1 4.686 3.775 24.781 24.781 0 0 1 .9 7.651V96.2a2.225 2.225 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391h13.161a2.01 2.01 0 0 0 1.529-.391A2.227 2.227 0 0 0 145 96.2z"
          className="logotype-path-fill"
          transform="translate(56.573 .377)"
        />
        <path
          id="path3218"
          d="M152.391 82.734a2.267 2.267 0 0 0-.582-.748 1.39 1.39 0 0 0-.881-.34 32.419 32.419 0 0 0-5.55 2.244q-4.171 1.964-11.333 2.244-9.114-.349-12-5.645a21.424 21.424 0 0 1-2.625-11.358h35.76a3.064 3.064 0 0 0 2.476-.731 4.126 4.126 0 0 0 .582-2.669 68.586 68.586 0 0 0-1.871-16.942 29 29 0 0 0-7.779-14.222q-5.773-5.739-16.937-5.97-13.51.259-21.236 10.185t-7.876 26.4q.216 18.626 8.308 27t21.469 8.229a52.573 52.573 0 0 0 15.836-2.686q7.457-2.516 7.96-5.339a2.791 2.791 0 0 0-.4-1.5zm-32.968-26.252a29.423 29.423 0 0 1 1.911-9.045q1.915-5.594 9.389-6.189 6.618.429 8.225 5.526a27.094 27.094 0 0 1 1.346 9.708z"
          className="logotype-path-fill"
          transform="translate(101.103 .377)"
        />
        <path
          id="path3220"
          d="M158.937 98.732a1.015 1.015 0 0 0-1.064-.816 8.581 8.581 0 0 0-1.894.612 9.667 9.667 0 0 1-3.29.612 3.1 3.1 0 0 1-3.257-2.448 21.053 21.053 0 0 1-.465-5.713V16.848a6.037 6.037 0 0 0-.266-2.3 1 1 0 0 0-1.063-.561 6.044 6.044 0 0 0-.881.2 5.214 5.214 0 0 1-.98.2l-11.167 2.325a6.018 6.018 0 0 0-1.861.612q-.656.383-.665 1.836v69.37a49.644 49.644 0 0 0 2.044 17.819q2.555 7.966 14.307 8.433a35.669 35.669 0 0 0 9.106-1.19q4.079-1.131 4.32-2.618a1.1 1.1 0 0 0-.083-.442 3.61 3.61 0 0 1-.183-.646z"
          className="logotype-path-fill"
          transform="translate(130.075 -13.989)"
        />
        <path
          id="path3222"
          d="M173.669 86.814q-6.788-.208-8.94-6.41t-1.961-15.9q-.179-9.7 2.011-15.9t8.89-6.41q6.7.208 8.89 6.41t2.011 15.9q.191 9.785-1.961 15.948t-8.94 6.362zm28.847-22.307q-.133-16.616-7.743-26.167t-21.1-9.742q-13.58.191-21.153 9.742t-7.694 26.167q.121 16.616 7.694 26.167t21.153 9.742q13.58-.191 21.153-9.742t7.69-26.167z"
          className="logotype-path-fill"
          transform="translate(142.686 .377)"
        />
        <path
          id="path3224"
          d="M228.515 48.467q-.395-12.042-5.9-16.1a17.375 17.375 0 0 0-11.383-3.758q-7.8.255-12.662 3.367a43.038 43.038 0 0 0-7.677 6.019 45.38 45.38 0 0 0-2.027-7.09q-.964-2.308-2.227-2.3a6.434 6.434 0 0 0-1.994.408l-10.1 3.4q-1.429.442-1.462 1.5a6.676 6.676 0 0 0 .655 2.63 22.86 22.86 0 0 1 1.216 5.259 103.67 103.67 0 0 1 .655 13.33V96.21a2.226 2.226 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391h13.161a2.01 2.01 0 0 0 1.529-.391 2.226 2.226 0 0 0 .332-1.513V48.467a24.977 24.977 0 0 1 4.4-3.4 14.4 14.4 0 0 1 7.295-2.176q4.914.23 6.265 3.775a19.06 19.06 0 0 1 1.18 7.651V96.21a2.225 2.225 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391h13.161a2.01 2.01 0 0 0 1.529-.391 2.227 2.227 0 0 0 .332-1.513z"
          className="logotype-path-fill"
          transform="translate(170.478 .367)"
        />
        <path
          id="path3226"
          d="M245.972 92.448a1.015 1.015 0 0 0-1.064-.816 15.351 15.351 0 0 0-3.955 1.292 18.247 18.247 0 0 1-6.547 1.292q-3.249-.111-3.955-2.516a18 18 0 0 1-.565-5.781V53h13.161a2.657 2.657 0 0 0 1.645-.306 2.861 2.861 0 0 0 .748-1.734l1.728-8.025a4.578 4.578 0 0 0 .116-.646 4.246 4.246 0 0 0 .017-.442q-.029-1.075-.681-1.309a4.084 4.084 0 0 0-1.579-.187h-15.154V21.309a4.41 4.41 0 0 0-.216-1.768q-.245-.552-1.113-.544a8.233 8.233 0 0 0-1.014.068 6.52 6.52 0 0 1-.715.068l-11.7 1.632a2.723 2.723 0 0 0-1.761.663 2.758 2.758 0 0 0-.368 1.785v17.139h-9.438a2.011 2.011 0 0 0-1.529.391 2.226 2.226 0 0 0-.332 1.513V51.1a2.226 2.226 0 0 0 .332 1.513 2.01 2.01 0 0 0 1.529.391H213v30.6a47.469 47.469 0 0 0 2.144 17.819q2.679 7.966 15.005 8.433a51.038 51.038 0 0 0 12.762-1.615q5.658-1.534 5.982-3.554a1.1 1.1 0 0 0-.083-.442 3.616 3.616 0 0 1-.183-.646z"
          className="logotype-path-fill"
          transform="translate(198.626 -9.065)"
        />
        <path
          id="path3228"
          d="M277.157 95.655a2.775 2.775 0 0 0 .847-.51.982.982 0 0 0 .349-.714 19.083 19.083 0 0 0-1.728-4.914q-1.512-3.26-1.728-12.769V50.5q.129-10.652-5.2-16.254T252.032 28.6a64.762 64.762 0 0 0-15.532 2.021q-7.357 1.917-7.86 4.37a3.689 3.689 0 0 0 .4 1.632l2.659 8.3a2.282 2.282 0 0 0 .465.884 1.185 1.185 0 0 0 .864.476 42.924 42.924 0 0 0 6.1-2.04 32.778 32.778 0 0 1 10.386-2.04q6.086.055 7.86 2.737a12.192 12.192 0 0 1 1.579 7.328v4.217a82.972 82.972 0 0 0-13.663.851 29.279 29.279 0 0 0-14.372 5.96q-6.311 4.9-6.662 16.176.1 9.7 5.384 15.268t14.556 5.679q7.374-.255 11.383-3.06a30.076 30.076 0 0 0 5.9-5.1 26.09 26.09 0 0 0 1.728 5.356q1.163 2.614 2.127 2.805a2.491 2.491 0 0 0 .864-.221 7.832 7.832 0 0 1 .864-.323zM258.945 82.6a21.766 21.766 0 0 1-3.739 3.2 11.4 11.4 0 0 1-6.231 1.972 8.516 8.516 0 0 1-6.281-2.635 9.5 9.5 0 0 1-2.493-6.614q.255-5.854 4.121-8.141a18.456 18.456 0 0 1 8.286-2.579 53.766 53.766 0 0 1 6.337-.161z"
          className="logotype-path-fill"
          transform="translate(220.801 .377)"
        />
      </g>
    </g>
  </svg>
)

export const PrideLogotype = () => (
  <svg
    id="prefix__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 886.24 164.6"
    className={'logotype'}
  >
    <defs>
      <style>
        {
          '.prefix__cls-1{fill:#feb700}.prefix__cls-2{fill:#2b9d58}.prefix__cls-3{fill:#fe4a00}.prefix__cls-4{fill:#005d9c}.prefix__cls-6{fill:#8a2cb4}.prefix__cls-9{fill:#fdfdfd}'
        }
      </style>
    </defs>
    <path
      className="prefix__cls-1"
      d="M309.37 372a22.57 22.57 0 01-3.82.72c-5.81.07-11.62 0-17.43 0-5.48 0-5.48 0-5.48-5.32 0-22.52.06-45-.07-67.56A55.51 55.51 0 00281.1 289c-.67-3.14-4.77-5.92-8.15-5.84a49.47 49.47 0 00-5 .62q2.18-11.11 4.34-22.23c9.44-2.77 18.82-3.39 27.06 3 3.09 2.39 5.08 6.21 7.78 9.63 2.88-2.41 6.06-5.13 9.33-7.76 7-5.63 15.3-6.78 23.87-6.54a34.75 34.75 0 019.55 1.56c7.85 2.55 10.59 9.5 12.49 16.47a63.66 63.66 0 012 16.08c.2 24.39.09 48.78.09 73.16 0 5.44-.24 5.65-5.76 5.65h-16.33c-5.08 0-5.11 0-5.12-5 0-22.81.07-45.62-.07-68.43a46.63 46.63 0 00-1.53-10.51c-.87-3.6-5.29-6.17-9.37-5.59a25.39 25.39 0 00-16.12 8.61 25.72 25.72 0 00-.55 3.81q-.06 32.73 0 65.47c-.04 3.61-.17 7.25-.24 10.84z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-2"
      d="M545.92 376.42c-12.06.1-23-4.47-31-14.36a49.23 49.23 0 01-7.92-14.44 89.5 89.5 0 01-4.87-20.34c-.72-6.86 0-13.9.43-20.84a56.15 56.15 0 018-25.71c7.77-13.11 18.77-20.48 34.3-20.88 8-.2 15.89.26 23 4.18 11.46 6.34 18.9 16.22 22.58 28.67 5.81 19.65 5.19 39.23-2.64 58.26-6.8 16.56-22.09 25.76-41.88 25.46zm20.53-58.24c-1.44-8.77-2.52-17.4-4.36-25.86-1.11-5.16-4.8-8.63-10.14-9.85-8.62-2-15.86 1.64-18.14 10.19a109 109 0 00-3.1 21.1c-.6 9.44-.38 18.94 2.42 28.12 1.57 5.15 3.63 10 9.48 11.93 8.7 2.86 16.84-.67 19.4-9.89 2.31-8.27 2.99-17.02 4.44-25.74z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-3"
      d="M191.29 364c-2.08 1.76-4 3.35-5.91 5-11.56 10.32-34.8 10.22-45.13-1.4-6.79-7.63-8.81-16.91-8.32-26.9.43-8.88 2.12-17.48 9.28-23.38 4.25-3.49 9.5-5.9 14.55-8.28 8.66-4.07 18.06-4.12 27.38-4 3.3.06 4.8-.92 4.34-4.34-.42-3.07-.74-6.15-1-9.23-.7-7.12-6.17-8.93-12.33-9.28-8.64-.49-16.56 2-24.4 5.19a33.33 33.33 0 01-5.23 1.11c-1.84-5.64-3.68-11-5.33-16.39a5.25 5.25 0 013.32-6.64c.19-.06.37-.11.56-.15 7.93-2.18 16-4.35 24.1-5.26 8.4-.93 17-1.07 25.35 1.43 11.16 3.36 16.95 11.55 19.31 22.38A67.52 67.52 0 01213 297.2c.23 16.49-.09 33 .58 49.46.23 5.83 2.38 11.66 4.23 17.31 1.1 3.35-.26 4.7-2.83 5.74-4.65 1.89-9.34 3.65-14 5.51-3.72 1.48-5.84.51-7.17-3.44-.9-2.48-1.67-5.04-2.52-7.78zm-3.83-40.73c-7.8 0-14.86.4-21.75 3.48-9.23 4.12-10.66 17.61-4.44 24.94s21.88 4.82 25.78-4.15a4.6 4.6 0 00.39-1.75v-22.52z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-1"
      d="M432.59 374.18c-15.56 3.5-30.77 4.08-44.95-5.06-7.77-5-12.34-12.08-15.51-20.69-4.63-12.58-5-25.54-4.15-38.49.88-13.37 5.24-25.84 14.14-36.26s20.39-14.94 33.94-13.71c5.44.5 11.09 1.22 16.09 3.23 9 3.63 14.18 11.47 18.38 19.91a4.57 4.57 0 00-.47 1q-4.67 20.75-9.32 41.5l-32.74.29h-13.23c-.37 9.49 2.08 17.83 8.83 23.81 4.42 3.91 10.83 4.75 16.79 4 5.31-.68 10.49-2.29 15.72-3.49-.13 1.32-.19 2.65-.39 4q-1.55 9.96-3.13 19.96zm-37.94-69.44h34c-.74-4.8-1.25-9.1-2.11-13.33-1.24-6.1-5-9.81-11.26-10.64s-11.78.65-15.44 5.84-4.54 11.57-5.19 18.15z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-4"
      d="M755.84 358.1c.46 2.57.84 5.15 1.4 7.7s.13 4.49-2.49 5.56c-13.22 5.38-26.68 7.29-40.46 2.44a15.92 15.92 0 01-10.75-11.91c-1.45-6.7-2.86-13.56-3-20.37-.42-17.7-.14-35.41-.14-53.12v-4.33c-4.79 0-9.25-.2-13.68.06-3.6.22-4.63-1.19-4.47-4.62.24-5.18.06-10.37.06-16 4.81 0 9.26.06 13.71 0a21 21 0 003.77-.75 9.2 9.2 0 00.58-2.25v-26.06c.84-.82 1.58-2.22 2.52-2.36 7-1.06 14-1.83 21-2.79 3.1-.42 3.7 1.18 3.68 3.8-.07 8.71 0 17.42 0 26.13 0 1.19.18 2.39.27 3.58 1.29.14 2.57.37 3.86.39 7.59.11 15.18.16 22.78.24.2 1.63.8 3.32.53 4.87-.7 4.13-1.66 8.22-2.75 12.26-.35 1.27-1.41 2.35-2.15 3.52-5.16 0-10.32-.08-15.47 0-2.3 0-4.59.38-6.89.58-.06 17-.17 33.93-.15 50.9a59 59 0 00.77 10.78c.78 4.17 4.06 6.17 8.26 5.16a78.88 78.88 0 009.59-3.35c2.51-1 4.94-2.58 7.53-.22q1 5.06 2.09 10.16zM620.47 274c6.4-4.9 11.87-11.23 20.28-13 10.09-2.12 20-2.89 28.85 3.92a24 24 0 018.91 14.7 86.83 86.83 0 011.55 16.16c.16 24.14 0 48.28.13 72.41 0 3.4-.78 4.81-4.45 4.64-5.81-.29-11.64-.08-17.46-.08-5.09 0-5.1 0-5.1-5 0-22.43.06-44.87-.06-67.31a38.42 38.42 0 00-1.36-9.65c-1.43-5.39-4.81-7.72-10.37-7.77-7.14 0-12.77 3.4-17.8 8-.84.76-1 2.62-1 4q-.09 36.38 0 72.76c0 5 0 5-5.12 5-5.57 0-11.15-.08-16.73-.12 1-6 1.76-12.14 2.92-18.15 3.68-19.13 7.41-38.24 11.31-57.32 1.59-7.77 3.66-15.44 5.5-23.19z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      d="M101.92 242.18c-10.86-3.12-21.82 2.68-25.28 14.82a118.77 118.77 0 00-4.38 26.94c-.72 15-.53 30.17 2.68 45.06 1.41 6.49 3.47 12.62 8.07 17.62q-1.86 13.85-3.71 27.68c-14-3.85-23.27-12.63-29.06-26a96 96 0 01-7-24.8 163.44 163.44 0 01-1.53-30.79 196.77 196.77 0 014.52-32.14 62.45 62.45 0 0113.41-27.06c8.61-10.23 19.29-15.42 32.43-15.68 5-.1 9.94.55 14.91.87-1.19 5-2.43 10-3.54 15.06-.6 2.73-1.02 5.59-1.52 8.42z"
      transform="translate(-41.55 -211.99)"
      fill="#fe000c"
    />
    <path
      className="prefix__cls-2"
      d="M468.84 216.25l18.77-4.25c.26 2.65.61 4.62.62 6.6q0 59.52.07 119a65.32 65.32 0 001.16 10.35c.58 3.41 3.24 3.79 6.08 3.57a11.54 11.54 0 002.1-.53c6.19-1.79 6.2-1.8 7.62 4.3 1 4.44 1.8 9 3.11 13.3.72 2.37-.07 3.55-2 4.18-3.64 1.19-7.29 2.64-11.05 3.1-8.85 1.09-17.93 1.62-25.25-4.72a21.64 21.64 0 01-5.94-9.21c-3.09-9.31-3.07-19-3-28.78.23-29.12.08-58.25.09-87.37 0-1.45.15-2.9.23-4.34q3.74-12.59 7.39-25.2z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-6"
      d="M788.58 306.1c5.66-.34 11.32-.86 17-1 2.69-.05 3.89-1 3.73-3.57a96.2 96.2 0 00-1-10.72c-1.22-6.81-6.27-8.37-12.4-8.48q3.55-11.16 7.09-22.33c18.58-.22 30.15 10.78 31.27 28.64 1 15.56.31 31.22.68 46.83a192.8 192.8 0 001.86 20.92c.38 2.89 1.87 5.67 3.06 8.41 1 2.24.09 3.46-1.85 4.28-5.18 2.21-10.41 4.33-15.57 6.58-3.2 1.39-5 .24-6-2.86-.94-2.7-2.13-5.32-3.5-8.67-11 11.87-24.39 14.8-39.5 10.63l6-32.84c.09.35.2.69.26 1 1 7.08 4.66 11.61 10.38 12.92 6 1.37 15-2 18.09-6.94a5.57 5.57 0 001.1-2.61c.09-7.59.05-15.17.05-23-9.77-.21-18.84.32-26.45 6.71q2.83-11.91 5.7-23.9z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-3"
      d="M272.31 261.57q-2.18 11.12-4.34 22.23l-12.42 8.06a5.39 5.39 0 00-.55 1.61v77.81c-1.24.5-2.48 1.41-3.74 1.44-6.43.15-12.87 0-19.3.11-2.92.06-4-.81-4-4 .09-24.75.22-49.51-.16-74.27-.13-8.28-.18-16.76-3.82-24.64-.43-.94.56-3.59 1.45-3.91 6.29-2.31 12.69-4.39 19.17-6.11 1.09-.29 3.47 1.16 4 2.35 1.66 3.91 2.74 8.07 4.11 12.35 6.29-4.93 11.29-11.54 19.6-13.03z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-2"
      d="M620.47 274c-1.84 7.72-3.91 15.39-5.49 23.17-3.9 19.08-7.63 38.19-11.31 57.32-1.16 6-2 12.1-2.92 18.15-2 0-4.5 1-5.3-2.11q0-37.72.06-75.46a8.15 8.15 0 00-.5-2q-1.23-9.6-2.49-19.21a15.27 15.27 0 00-.58-2c-1.32-4.52-.85-5.48 3.55-6.92 5.1-1.68 10.21-3.32 15.33-5 3.25-1.05 5 .59 5.92 3.47.8 2.5 1.41 5.06 2.22 7.56a23.12 23.12 0 001.51 3.03z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-6"
      d="M903.62 336.62c4.93-4.76 10.36-5.91 16.4-3.19 5.78 2.6 7.59 7.46 7.76 13.48.2 6.69-3.24 11.61-7.56 15.83s-9.37 7.58-14 11.44c-2 1.68-3.71 1.41-5.7 0-7.08-5.12-14.21-10.2-18.73-18a17.36 17.36 0 01-.1-18.25c3.33-5.85 12.38-7.73 18.23-4 1.22.75 2.34 1.68 3.7 2.69z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-4"
      d="M779.41 341.92l-6 32.84a26.75 26.75 0 01-16.29-15 9.13 9.13 0 00-1.24-1.65q-1.11-5.11-2.21-10.11c.28-6.7.19-13.37 2.45-19.91 3.53-10.17 11.37-15.24 20.77-18.62 3.81-1.37 7.8-2.23 11.71-3.32l-5.74 24q-1.72 5.85-3.45 11.77z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-3"
      d="M79.3 374.25q1.86-13.85 3.71-27.68c7.21 7.79 16 7.73 25 4.52 3.33-1.17 6.2-3.61 9.41-5.21 5.21-2.6 6.21-2 8.48 3.42 1.39 3.3 3.31 6.39 5 9.55 2.14 3.93 1.6 5.22-1.92 7.91-8.75 6.7-18.93 9.28-29.73 9.63-6.68.22-13.41.21-19.95-2.14z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-4"
      d="M803.06 260q-3.57 11.18-7.12 22.33c-10.43-1.36-19.51 4.12-29.34 6.29-1.92-5.39-3.76-10.25-5.38-15.18a6.59 6.59 0 014.19-8.32l.22-.07a86.1 86.1 0 0128.15-5.16c3.06.02 6.22.11 9.28.11z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      className="prefix__cls-3"
      d="M101.92 242.18c.5-2.83.9-5.69 1.52-8.5 1.11-5 2.35-10 3.54-15.06 4.13 1.53 8.46 2.68 12.32 4.71a62.35 62.35 0 0110.37 7.38c2.24 1.84 2.91 4.13.83 7-2.63 3.56-4.77 7.48-7.22 11.18-2.1 3.16-3.71 3.41-6.78 1.17-4.5-3.33-8.66-7.27-14.58-7.88z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      d="M440.74 325.59q4.67-20.76 9.32-41.5a4.57 4.57 0 01.47-1c5.24 12.56 6.46 25.76 5.89 39.18 0 1.11-1.94 2.93-3.1 3a117.16 117.16 0 01-12.58.32z"
      transform="translate(-41.55 -211.99)"
      fill="#2b9e58"
    />
    <path
      className="prefix__cls-2"
      d="M432.59 374.18q1.57-10 3.13-20.06c.2-1.3.26-2.63.39-4 2.42-1.36 4.76-2.89 7.28-4 1.94-.88 3.51-.06 4.3 2 1.52 4 2.85 8.11 4.65 12 1.53 3.29.84 6-2.08 7.36-5.73 2.63-11.76 4.52-17.67 6.7z"
      transform="translate(-41.55 -211.99)"
    />
    <path
      d="M468.84 216.25q-3.69 12.62-7.39 25.23c-.11-7.32-.3-14.63-.25-21.94a3.39 3.39 0 012-2.38 32.66 32.66 0 015.64-.91z"
      transform="translate(-41.55 -211.99)"
      fill="#fdba0a"
    />
    <path
      className="prefix__cls-9"
      d="M309.37 372c.07-3.63.2-7.27.2-10.9v-65.43a25.72 25.72 0 01.55-3.81v76.85a14.61 14.61 0 01-.75 3.29zM255 371.28v-77.81a5.39 5.39 0 01.55-1.61v76.31a17.32 17.32 0 01-.55 3.11zM595 293.06a8.15 8.15 0 01.5 2q0 37.74-.06 75.46a9.3 9.3 0 01-.5-2q.06-37.72.06-75.46zM700.34 234.46v26.06a9.2 9.2 0 01-.58 2.25v-24.49a29.12 29.12 0 01.58-3.82zM754.38 263.45c-7.6-.08-15.19-.13-22.78-.24a36 36 0 01-3.86-.39h22.82a24.75 24.75 0 013.82.63zM727.65 284.68c2.3-.2 4.59-.55 6.89-.58 5.15-.08 10.31 0 15.47 0-3.94.19-7.89.45-11.83.56-3.51.09-7.02.02-10.53.02z"
      transform="translate(-41.55 -211.99)"
    />
  </svg>
)
