// @flow
import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

// Components
import { Portal } from '../Portal'
import { Hamburger } from '../Hamburger'

// Styling
import './drawer.scss'

// Types

type CloseClickEvent =
  | React.MouseEvent<HTMLDivElement>
  | React.KeyboardEvent<HTMLDivElement>
  | React.MouseEvent<HTMLButtonElement>

interface Props {
  isOpen: boolean
  children: React.ReactNode | React.ReactNode[]
  style?: 'left' | 'right' | 'center' | 'cover'
  className?: string
  onClose?: (event: CloseClickEvent) => void
}

export const Drawer: React.FC<Props> = ({
  isOpen,
  children,
  style,
  className,
  onClose,
}) => {
  const [enterAnimationStatus, setAnimationStatus] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('locked')
    } else {
      document.body.classList.remove('locked')
    }

    return (): void => {
      document.body.classList.remove('locked')
    }
  }, [isOpen])

  const handleOnClose = (event: CloseClickEvent): void => {
    event.preventDefault()
    if (onClose) {
      onClose(event)
    }
  }

  const handleOnEntered = (): void => {
    setAnimationStatus(true)
  }

  const handleOnExited = (): void => {
    setAnimationStatus(false)
  }

  const drawerClasses = classNames('drawer', {
    'drawer--left': style === 'left',
    'drawer--right': style === 'right',
    'drawer--cover': style === 'cover',
    'drawer--center': style === 'center',
  })

  const drawerWrapperClasses = classNames('drawer__wrapper', {
    [`${className}`]: className,
    'is-visible': enterAnimationStatus === true,
  })

  return (
    <Portal>
      <div className="portal__drawer">
        <CSSTransition
          key="drawer"
          in={isOpen}
          timeout={450}
          onEntered={handleOnEntered}
          onExited={handleOnExited}
        >
          {(state): React.ReactNode => (
            <div
              className={`${drawerClasses} animation-${state}`}
              role={'dialog'}
            >
              {style !== 'cover' && (
                <div
                  className="drawer__backdrop"
                  role="button"
                  aria-label="Close Drawer"
                  tabIndex={-10}
                  onClick={handleOnClose}
                />
              )}
              <div className={drawerWrapperClasses}>{children}</div>
              {style === 'cover' && (
                <div className={'drawer__close'}>
                  <Hamburger status="open" onClick={handleOnClose} />
                </div>
              )}
            </div>
          )}
        </CSSTransition>
      </div>
    </Portal>
  )
}

Drawer.defaultProps = {
  style: 'right',
  className: '',
}
