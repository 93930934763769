import { useStaticQuery, graphql } from 'gatsby'

export interface ContentfulMainNavigationPage {
  slug: string
  title: string
}

export interface ContentfulMainNavigationLink {
  id: string
  title: string
  page: ContentfulMainNavigationPage
}

export interface UseMenuQuery {
  id: string
  links: ContentfulMainNavigationLink[]
}

export const useHeaderQuery = (): UseMenuQuery => {
  const { contentfulMenu } = useStaticQuery(graphql`
    query MainNavigationQuery {
      contentfulMenu(location: { eq: "Header Navigation" }) {
        id
        links {
          id
          title
          page {
            slug
            title
          }
        }
      }
    }
  `)
  return contentfulMenu
}
