import React, { FC } from 'react'
import { object, string, bool } from 'yup'
import { useFormik, FormikValues } from 'formik'
import axios from 'axios'

// Components
import { CheckBox, SubmitButton, TextField } from 'components/Inputs'

import './newsletter-form.scss'

const schema = object().shape({
  email: string()
    .email('Vänligen ange en korrekt emailadress')
    .required('Vänligen ange din emailadress'),
  name: string().required('Vänligen ange ditt namn'),
  acceptPolicy: bool()
    .oneOf(
      [true],
      'Du behöver gå med på att vi hanterar dina uppgifter för att få nyhetsbrev'
    )
    .required(
      'Vänligen klicka i att du går med på att vi hanterar dina uppgifter'
    ),
})

const getErrorMessage = (field: string, form: FormikValues) => {
  if (form.errors[field] && form.touched[field]) {
    return form.errors[field]
  }
}

const NewsletterForm: FC = () => {
  const form = useFormik({
    initialValues: {
      email: '',
      name: '',
      acceptPolicy: false,
    },
    validationSchema: schema,
    onSubmit: (values, helpers) => {
      axios
        .post(`/.netlify/functions/newsletter`, {
          email: values.email,
          name: values.name,
        })
        .then((response) => {
          console.log(response)
          helpers.setSubmitting(false)
          helpers.setStatus('SUCCESS')
        })
        .catch((error) => {
          console.log(error)
          helpers.setSubmitting(false)

          if (error.response) {
            helpers.setStatus(error.response.error)
          } else {
            helpers.setStatus('UNKNOWN_ERROR')
          }
        })
    },
  })

  if (form.status === 'SUCCESS') {
    return <p>Grattis! Du prenumerar nu på våra nyhetsbrev. Glad läsning!</p>
  }

  return (
    <div className="newsletter-form">
      <h3 className="newsletter-form__title">Missa inte vårt nyhetsbrev!</h3>
      <p className="newsletter-form__text">
        Signa upp dig idag och få tillgång till de senaste branschnyheterna och
        andra schyssta tips.
      </p>
      <form onSubmit={form.handleSubmit}>
        <div>
          <TextField
            id="name"
            name="name"
            type="text"
            value={form.values.name}
            placeholder="Namn"
            autoComplete={'name'}
            errorMessage={getErrorMessage('name', form)}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div>
          <TextField
            id="email"
            name="email"
            type="email"
            value={form.values.email}
            placeholder="Email"
            autoComplete={'email'}
            errorMessage={getErrorMessage('email', form)}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div>
          <CheckBox
            id={'acceptPolicy'}
            checked={form.values.acceptPolicy}
            name="acceptPolicy"
            value={'acceptPolicy'}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            errorMessage={getErrorMessage('acceptPolicy', form)}
          >
            Jag godkänner att Camelonta hanterar mina personuppgifter
          </CheckBox>
        </div>

        {(form.status === 'Invalid Resource' ||
          form.status === 'UNKNOWN_ERROR') && (
          <div>
            <p>Någonting gick tyvärr fel</p>
          </div>
        )}

        <div className="contact-form__group">
          <SubmitButton disabled={form.isSubmitting}>Prenumerera</SubmitButton>
        </div>
      </form>
    </div>
  )
}

export default NewsletterForm
