import React, { FC } from 'react'
import cn from 'classnames'

import './label.scss'

interface Props {
  for?: string
  className?: string
}

const Label: FC<Props> = (props) => {
  return (
    <label htmlFor={props.for} className={cn('form-label', props.className)}>
      {props.children}
    </label>
  )
}

export default Label
