import React, { FC } from 'react'
import cn from 'classnames'

import './input-group.scss'

interface Props {
  className?: string
}

const InputGroup: FC<Props> = (props) => {
  return (
    <div className={cn('input-group', props.className)}>{props.children}</div>
  )
}

export default InputGroup
