import React, { FC } from 'react'

const UCSigil: FC = () => {
  const org = '5566693858'
  const lang = 'swe'
  return (
    <div>
      <a
        href={`https://www.uc.se/risksigill2?showorg=${org}&language=${lang}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={`https://www.uc.se/ucsigill2/sigill?org=${org}&language=${lang}&product=lsa&fontcolor=w&type=svg`}
        />
      </a>
    </div>
  )
}

export default UCSigil
