/* eslint-disable */
import { useRef, DependencyList, useEffect } from 'react'

const isBrowser = typeof window !== `undefined`

interface Properties {
  element?: string
  useWindow: boolean
}

function getScrollPosition({ element, useWindow }: Properties): {
  x: number
  y: number
} {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? document.querySelector(element) : document.body
  const position = target
    ? target.getBoundingClientRect()
    : document.body.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

function doElementsCollide(element: HTMLElement, target: Element): boolean {
  const targetIsFixed = getComputedStyle(target).position === 'fixed'

  if (targetIsFixed) {
    const targetRect = target.getBoundingClientRect()

    const startingPosition = window.scrollY + targetRect.height / 2
    const endingPosition = element.offsetTop + element.offsetHeight

    return (
      element.offsetTop <= startingPosition &&
      startingPosition <= endingPosition
    )
  }

  return false
}

interface Rect {
  x: number
  y: number
}

interface Result {
  currentPosition: Rect
  previousPosition: Rect
  node: Element
}

export function useCurrentOverlay(
  effectCallback: ({ currentPosition, previousPosition, node }: Result) => void,
  element: string,
  targets: string | string[],
  deps?: DependencyList,
  useWindow = false
) {
  const positionRef = useRef(getScrollPosition({ useWindow }))

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    let animationId: number | null = null
    let throttleTimeout: NodeJS.Timeout | null = null

    const checkElements = (): void => {
      const currentPosition = getScrollPosition({ element, useWindow })
      const previousPosition = positionRef.current
      const queryElements = Array.isArray(targets) ? targets.join(',') : targets
      const elementNode = document.querySelector(element)
      const nodes: NodeListOf<HTMLElement> =
        document.querySelectorAll(queryElements)

      if (nodes && elementNode) {
        nodes.forEach((node) => {
          const isHit = doElementsCollide(node, elementNode)

          if (isHit) {
            effectCallback({ currentPosition, previousPosition, node })
            positionRef.current = currentPosition
          }
        })
      }

      throttleTimeout = null
    }

    function handleOnScroll() {
      animationId = window.requestAnimationFrame(checkElements)
      if (!window.requestAnimationFrame && throttleTimeout === null) {
        throttleTimeout = setTimeout(checkElements, 200)
      }
    }

    window.addEventListener('scroll', handleOnScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleOnScroll)
      if (typeof animationId === 'number') {
        window.cancelAnimationFrame(animationId)
      }
      if (typeof throttleTimeout === 'number') {
        clearTimeout(throttleTimeout)
      }
    }
  }, deps)
}

useCurrentOverlay.defaultProps = {
  deps: [],
  element: false,
  useWindow: true,
}
