import React from 'react'

// Components
import Header from 'components/Header'
import Footer from 'components/Footer'
import CookieBanner from 'components/CookieBanner'

// Styling
import './layout.css'

interface Props {
  children: React.ReactNode[] | React.ReactNode
  theme: 'Light' | 'Dark'
}

const Layout: React.FC<Props> = ({ theme, children }) => {
  return (
    <>
      <Header pageTheme={theme} />
      <main role="main">{children}</main>
      <Footer title={'Behöver du någon som är asgrym på webb?'}>
        <p>
          Hör av dig till oss! Vi vill veta mer om dig och dina utmaningar. Ring
          på <a href="tel:086188550">08-618 85 50 </a> eller maila till{' '}
          <a href="mailto:kontakt@camelonta.se">kontakt@camelonta.se</a>
        </p>
      </Footer>
      {/* <CookieBanner /> */}
    </>
  )
}

export default Layout
