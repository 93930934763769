import React from 'react'

import { InputGroup, Label } from 'components/Form'

interface Props {
  id: string
  name: string
  value: string
  label?: string
  placeholder?: string
  type?: string
  errorMessage?: string
  autoComplete?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const TextField: React.FC<Props> = ({
  id,
  name,
  value,
  label,
  placeholder,
  type,
  errorMessage,
  autoComplete,
  onChange,
  onBlur,
}) => (
  <InputGroup className="input-group--text">
    {label && <Label for={id}>{label}</Label>}
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete={autoComplete}
      className={'input-group__input'}
    />
    {errorMessage && (
      <span className={'input-group__errorMessage'}>{errorMessage}</span>
    )}
  </InputGroup>
)

TextField.defaultProps = {
  type: 'text',
}
