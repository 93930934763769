import React from 'react'
import classNames from 'classnames'

import './hamburger.scss'

interface Props {
  status: 'open' | 'closed'
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const Hamburger: React.FC<Props> = ({ onClick, status }) => {
  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault()
    onClick(event)
  }

  const classes = classNames('hamburger', {
    'hamburger--open': status === 'open',
    'hamburger--closed': status === 'closed',
  })

  return (
    <div className={classes} onClick={handleOnClick}>
      <span className="hamburger__line" />
      <span className="hamburger__line" />
      <span className="hamburger__line" />
    </div>
  )
}
