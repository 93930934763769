import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

// Components
import { Navigation } from './Navigation'
import { Logotype, PrideLogotype } from './Logotype'
import { Hamburger } from '../Hamburger'
import { Drawer } from '../Drawer'

// Utils
import { useHeaderQuery } from 'utils/queries'
import { useCurrentOverlay } from 'utils/useCurrentOverlay'

// Styling
import './header.scss'

interface CallbackProps {
  prevPos: {
    x: number
    y: number
  }
  currPos: {
    x: number
    y: number
  }
}

interface Props {
  pageTheme: 'Light' | 'Dark'
}

type Theme = 'Light' | 'Dark'

let ticking = false
const isBrowser = typeof window !== `undefined`

const Header: React.FC<Props> = ({ pageTheme }) => {
  const [hasScrolledDown, setHasScrolledDown] = useState(false)
  const [scrollTop, isScrollTop] = useState(true)
  const [theme, setTheme] = useState<Theme>(pageTheme)
  const [mobileMenuVisible, toggleMobileMenu] = useState<'open' | 'closed'>(
    'closed'
  )

  useScrollPosition(
    ({ prevPos, currPos }: CallbackProps) => {
      // if current is bigger then previous then we are scrolling down
      const isScrollingDown = currPos.y < prevPos.y
      if (currPos.y === 0) {
        setHasScrolledDown(false)
      } else if (isScrollingDown !== hasScrolledDown) {
        setHasScrolledDown(isScrollingDown)
      }
    },
    [hasScrolledDown],
    null,
    false,
    200
  )

  const onScroll = () => {
    if (!isBrowser) isScrollTop(true)

    if (window.pageYOffset === 0) {
      setTheme(pageTheme)
      isScrollTop(true)
    } else {
      isScrollTop(false)
    }
  }

  const requestTick = function () {
    if (!ticking) {
      window.requestAnimationFrame(onScroll)
      ticking = true
    }
  }

  useEffect(() => {
    requestTick()

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  useCurrentOverlay(
    ({ node }) => {
      const needLight = node.classList.contains('header-light')
      const needDark = node.classList.contains('header-dark')

      if (!scrollTop) {
        setTheme('Dark')
      } else if (theme === 'Light' && needDark) {
        setTheme('Dark')
      } else if (theme === 'Dark' && needLight) {
        setTheme('Light')
      }
    },
    '.site-header',
    ['.header-dark', '.header-light'],
    [theme],
    true
  )

  const menu = useHeaderQuery()

  const handleOnHamburgerClick = () => {
    toggleMobileMenu(mobileMenuVisible === 'open' ? 'closed' : 'open')
  }

  const handleOnDrawerCloseClick = () => {
    toggleMobileMenu('closed')
  }

  const headerStyle = {
    transition: `transform 300ms ${
      hasScrolledDown
        ? 'cubic-bezier(.4, 0, 1, 1)'
        : 'cubic-bezier(0, 0, .2, 1)'
    }`,
    transform: `translateY(${hasScrolledDown ? '-100%' : '0%'})`,
    color: `${scrollTop && theme === 'Light' ? '#fff' : '#000'}`,
    background: `${scrollTop ? 'transparent' : '#fff'}`,
  }

  const scrollClass = scrollTop ? 'top' : 'scrolled'

  return (
    <header
      className={`site-header header--${theme}--${scrollClass}`}
      style={headerStyle}
    >
      <div className="site-header__logotype">
        <Link to="/" title="Gå till startsidan">
          <Logotype theme={pageTheme} />
        </Link>
      </div>

      <Navigation links={menu.links} />

      <Hamburger status={mobileMenuVisible} onClick={handleOnHamburgerClick} />

      <Drawer
        isOpen={mobileMenuVisible === 'open'}
        onClose={handleOnDrawerCloseClick}
        style={'cover'}
      >
        <Navigation links={menu.links} />
      </Drawer>
    </header>
  )
}

export default Header
